
.birthfilter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-left: 16px;
    flex-wrap: wrap;
    gap: 8px;
    
}
.birthboxs{
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 16px;
    align-items: stretch;
}
.boxinn{
    background-color: #fff;
    box-shadow: rgb(255, 255, 255) 0px 1px 4px;
    align-items: center;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
}
.imagemainn img{

    width: 120px;
    object-fit: cover;
    border-radius: 5px;
}
.objfirsdtcontinn{
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    
}
.imagemainn{
    width: 120px;
    flex-shrink: 0;
}

.textdecinn{
    width: 100%;
}
.textdecinn h5{
    color: #000 !important;
    font-size: 16px;
}
.textdecinn p{
    margin-bottom: 0;
    color: #0C090A;
}
.single-channel-page .container-fluid {
    padding: 30px 10px 30px 10px !important;
}
@media (min-width: 1600px){
    .col-xl-4 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

