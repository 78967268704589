.top-mobile-search {
	display: none;
}
.top-mobile-search {
	margin: -16px -15px 19px -18px;
}
.top-mobile-search input {
	border-radius: 0;
	box-shadow: none !important;
}
.top-mobile-search .btn {
	border-radius: 0;
	font-size: inherit;
}
@media (max-width: 575.98px) {
	.top-mobile-search {
		display: block;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.top-mobile-search {
		display: block;
	}
}

.bg-white{
    background-color: #232323 !important;
}

.row{
	margin-right:0px;margin-left:0px
}

.content-section{
	padding-top:20px;
	padding-bottom:10px;
	padding-left:10px;
	padding-right:10px;
	margin-right:0px!important;
	margin-left:0px!important
}

/* .content-section{padding-top:10px;padding-left:0px;padding-right:0px} */


@media screen and (min-width: 1475px) {
	.col.mgz {
		width: 12.5% !important;
		min-width: 12.5% !important;
		max-width: 12.5% !important;
	}
}


@media (max-width: 1475px) and (min-width: 960px) {
	.col.mgz {
		width: 20% !important;
		min-width: 20% !important;
		max-width: 20% !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.col.mgz {
		width: 33.3% !important;
		min-width: 33.3% !important;
		max-width: 33.3% !important;
	}
}

@media (min-width: 300px) and (max-width: 600px) {
	.col.mgz {
		width: 50% !important;
		min-width: 50% !important;
		max-width: 50% !important;
	}
}

@media (max-width: 300px) {
	.col.mgz {
		width: 100% !important;
		min-width: 100% !important;
		max-width: 100% !important;
	}
}

.title-more{
	margin-top: 5px;
    width: 100%;
    font-size: 13pt;
    font-weight: 800;
    display: block;
    text-decoration: none;
    margin-top: 10px;
    color: #ff0000!important;
}

.title-more:hover{
	color: #ff0000!important;
}


.checked{
	color:red;
}

/* .landingslide{
	position: relative;
    padding: 10px;
    padding-right: 0;
    display: inline-block;
    transition: transform .1s;
    margin-bottom: 5px;
    margin-top: 5px;
} */

/* .channeldetailslide img{
	transition: transform .1s;
    background: grey;
    width: 200px;
    min-height: 70px;
    border-radius: 5px;
    height: auto;
} */

.channel.col-md-2, .channel.col-4 {
    margin-bottom: 0;
    margin-top: 0;
}
@media (min-width: 1700px){
.col-xxl-2  {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10% !important;

}
}
.channel {
    position: relative;
    /* padding: 10px; */
    padding-right: 0;
	padding-left: 10px;
    display: inline-block;
    transition: transform .1s;
    /* margin-bottom: 5px;
    margin-top: 5px; */
}
/* @media (min-width: 768px){
.col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
} */

.nextbtn {
    /* width: 40px; */
	height: 74px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    border: none;
    font-size: 15pt;
	margin-top: 5px;
	background: #020024;
    background: linear-gradient(to right,transparent,black);
    color: #b3b3b3;
}
/* .dark-mode .nextbtn .prevbtn{
    background: #020024;
    background: linear-gradient(to right,transparent,black);
    color: #b3b3b3;
} */

.prevbtn {
    display: none;
    /* width: 40px; */
	height: 74px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    border: none;
    font-size: 15pt;
	margin-top: 5px;
	background: #020024;
    background: linear-gradient(to right,transparent,black);
    color: #b3b3b3;
}