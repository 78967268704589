.login-main-wrapper {
	background: #ff516b;
	/* Old browsers */
	background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
	height: calc(100vh - 0rem);
}
.full-height {
	height: calc(100vh - 0rem);
}
.login-main-left {
	margin: auto;
	max-width: 335px;
}
.login-main-right.bg-white {
	border-radius: 2px;
	margin: auto;
	max-width: 561px;
}
.carousel-login-card h5 {
	font-size: 18px;
}
.login-main-wrapper .form-control {
	background: transparent none repeat scroll 0 0 !important;
}
.login-main-right .owl-theme .owl-controls .owl-page span {
	background: #dcdcdc;
	border-radius: 50px;
	height: 16px;
	margin: 0 2px;
	opacity: unset;
	width: 16px;
}
.btn-lg {
	font-size: 15px !important;
	padding: 12px 16px !important;
}
.login-main-right .owl-theme .owl-controls .owl-page.active span,
.login-main-right .owl-theme .owl-controls .owl-page span:hover {
	background: #ff516b;
	/* Old browsers */
	background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
}
