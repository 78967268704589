.adblock {
	background: #eceff0 none repeat scroll 0 0;
	border-radius: 2px;
	margin-bottom: 1rem;
	padding: 27px 10px;
	text-align: center;
	width: 100%;
}
.video-card-list .video-card-image {
	float: left;
	height: 77px;
	margin: 0 12px 0 0;
	width: 122px;
}
.video-card-list .video-card-body {
	padding: 0;
}
.video-card-list {
	background: transparent none repeat scroll 0 0;
	box-shadow: none;
	display: inline-block;
	margin-bottom: 1rem;
	width: 100%;
}
.video-card-list .video-card-body .video-title a {
	font-size: 13px;
}
.video-card-list .video-card-body .video-title {
	line-height: 17px;
}
.video-card-list .video-card-body .right-action {
	opacity: 0;
}
.video-card-list:hover .video-card-body .right-action {
	opacity: unset;
}

/* Comment Box */
.single-video-comment-tabs .nav.nav-tabs {
	border-bottom: 2px solid #e7e9ee;
	margin: -15px -15px 15px;
	padding: 0 15px;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link {
	border-color: #b7b9be !important;
	border-radius: 2px !important;
	border-style: none none solid;
	border-width: 0 0 2px;
	color: #b7b9be !important;
	font-weight: 600;
	margin: 0 !important;
	padding: 16px 17px !important;
	text-transform: uppercase;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link.active,
.single-video-comment-tabs .nav.nav-tabs .nav-link:hover {
	border-color: #000 !important;
	color: #000 !important;
}
.single-video-comment-tabs .nav-tabs .nav-item {
	margin-bottom: -2px;
}

/* Comment Media, Custom Media */
.reviews-members {
	border-bottom: 1px solid #dcdcdc;
	margin: 0 -15px;
	padding: 15px;
}
.reviews-members-header .text-black {
	color: #000 !important;
	font-weight: 600;
}
.reviews-members-header .text-gray {
	color: #7c7c7c !important;
	font-size: 11px;
	font-weight: 700;
	margin: 0 6px;
}
.reviews-members-header {
	margin-bottom: 11px;
}
.reviews-members-body p {
	color: #7b7b7b;
	font-size: 13px;
}
.reviews-members-footer {
	display: inline-block;
	width: 100%;
}
.reviews-members .media img {
	border-radius: 50px;
	height: 51px;
	width: 51px;
}
.reviews-members:last-child {
	border: medium none;
	padding-bottom: 5px;
}

/* UsersWhoLiked */
.total-like-user-main img {
	border: 2px solid #fff;
	box-shadow: 0 0 2px #ccc;
	height: 25px !important;
	margin: 0 0 0 -11px;
	width: 25px !important;
}

.total-like {
	border: 1px solid #ff516b;
	border-radius: 3px;
	color: #ff516b !important;
	font-size: 11px;
	padding: 4px 10px;
	vertical-align: sub;
}
.single-video-left .total-like:hover {
	background: #ff516b none repeat scroll 0 0;
	border: 1px solid #ff516b;
	border-radius: 3px;
	color: #ffffff !important;
	font-size: 11px;
	padding: 4px 10px;
	vertical-align: sub;
}
.total-like-user-main.ml-2 {
	margin: 0 9px 0 17px !important;
}

/**/
.video-slider-right-list {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	height: 314px;
	overflow: auto;
}
.video-slider-right-list .video-card-list .video-card-image {
	height: 59px;
	width: 95px;
}
.video-slider-right-list .video-card-image img {
	object-fit: scale-down;
}
.video-slider-right-list .video-title a {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.video-slider-right-list .video-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.video-slider-right-list .video-card-list {
	cursor: pointer;
	margin-bottom: 1px;
	padding: 9px;
}
.video-slider-right-list .video-card-list:hover,
.video-slider-right-list .video-card-list.active {
	background: #eceff0 none repeat scroll 0 0;
}
.video-slider-right-list::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}
.video-slider-right-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

/**/

.box {
	padding: 15px;
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	transition-duration: 0.4s;
}
.single-video-title h2 {
	font-size: 18px;
}
.single-video-title h2 a {
	color: #333 !important;
}
.single-video-author img {
	border-radius: 50px;
	float: left;
	height: 38px;
	margin: 0 13px 0 0;
	width: 38px;
}
.single-video-author p {
	margin: 0;
	padding: 0;
}
.single-video-info-content h6 {
	font-size: 15px;
	font-weight: 600;
}

@media (max-width: 575.98px) {
	.single-video-author .float-right {
		float: none !important;
		margin: 0 0 14px;
	}
	.single-video-author {
		text-align: center;
	}
	.single-video-author img {
		float: none;
		margin: 0 0 5px;
	}
	.video-card-image img {
		width: 100%;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.video-card-image img {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.video-card-image img {
		width: 100%;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.video-card-image img {
		width: 100%;
	}
}
