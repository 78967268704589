.video-card-image {
	border-radius: 2px;
	/* box-shadow: 0 0 11px #ececec; */
	overflow: hidden;
	position: relative;
}
.time {
	background: #f5234a none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 5px;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	opacity: 0.7;
	padding: 0 6px;
	position: absolute;
	right: 5px;
}
.video-card:hover .time {
	opacity: unset;
}
.video-card-image img {
	width: 100%;
}
.play-icon {
	opacity: 0;
	position: absolute;
}
.play-icon .fas,
.play-icon svg {
	bottom: 0;
	height: 40px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
}
.video-card:hover .play-icon {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 0;
	color: #fff;
	font-size: 35px;
	left: 0;
	opacity: 0.4;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	transition-duration: 0.4s;
}
.video-title a {
	color: white !important;
	font-weight: 600;
	text-decoration: none;
}
.video-title {
	margin-bottom: 5px;
}
.video-page {
	font-size: 11px;
}
.video-view {
	color: #acacac;
	font-size: 10px;
	font-weight: 500;
	margin: 2px 0 0;
}
.video-card {
	background: #1c1c1c none repeat scroll 0 0;
	border-radius: 2px;
	/* box-shadow: 0 0 11px #ececec; */
	transition-duration: 0.4s;
}
.video-card-body {
	padding: 12px;
}
