.category-item img {
	background: #fff none repeat scroll 0 0;
	/* border-radius: 50px; */
	box-shadow: 0 0 2px #ccc;
	height: 170px;
	margin: 1px 0 8px;
	object-fit: scale-down;
	transform: scale(1);
	width: auto;
	min-height: 170;
}
.category-item:hover img {
	transform: scale(1.09);
	transition-duration: 0.4s;
}
.category-item {
	background: #1c1c1c none repeat scroll 0 0;
	border-radius: 2px;
	/* box-shadow: 0 0 6px #ececec; */
	margin: 6px 2px;
	padding: 12px 6px;
	text-align: center;
	min-height: 200px;
	height: auto;
}
.top-category .category-item {
	padding: 20px 11px;
}
.category-item h6 {
	color: white;
	font-size: 13px;
	margin: 5px 0 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.category-item p {
	color:red;
	font-size: 11px;
	font-weight: 500;
	margin: 0;
}



@media screen and (min-width: 1370px) {
	.col.mgz {
		width: 10% !important;
		min-width: 10% !important;
		max-width: 10% !important;
	}
}

@media (min-width: 800px) and (max-width: 1370px) {
	.col.mgz {
		width: 16.6% !important;
		min-width: 16.6% !important;
		max-width: 16.6% !important;
	}
}


@media (min-width: 400px) and (max-width: 800px) {
	.col.mgz {
		width: 20% !important;
		min-width: 20% !important;
		max-width: 20% !important;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.category-item {
		display: block;
	}
}
