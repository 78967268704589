/*
Template Name: VIDOE - Video Streaming Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
	-- Body
	-- Nav
	-- Sidebar
	-- Customize Bootstrap
	-- Category Slider
	-- Video Card
	-- Channels Card
	-- Upload Details
	-- Watch History
	-- Single Channel
    -- Video Page
    -- My Account
	-- Footer
*/
/* Body */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

html {
    position: relative;
    min-height: 100%;
}

body {
    background-color: #121212;
    color: #888;
    font-size: 13px;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}
.sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 99;
}

.osahan-nav {
    padding: 0;
    z-index: 9999 !important;
    /* position: fixed; */
}



#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#wrapper #content-wrapper {
    overflow-x: hidden;
    width: 100%;
}

#wrapper #content-wrapper .container-fluid {
    padding: 30px 30px 30px 90px;
}

.sidebar-toggled #wrapper #content-wrapper .container-fluid {
    padding: 30px 30px 30px 121px;
}

.single-channel-page {
    padding: 0px 0px 0px 90px;
}

.sidebar-toggled .single-channel-page {
    padding: 0px 0px 0px 90px;
}

.single-channel-page .container-fluid {
    padding: 30px 30px 30px !important;
}

body.fixed-nav #content-wrapper {
    margin-top: 56px;
    padding-left: 90px;
}

body.fixed-nav.sidebar-toggled #content-wrapper {
    padding-left: 0;
}

@media (min-width: 768px) {
    body.fixed-nav #content-wrapper {
        padding-left: 225px;
    }

    body.fixed-nav.sidebar-toggled #content-wrapper {
        padding-left: 90px;
    }
}

.carousel-login-card {
    padding: 11px 0;
}

.modal-content {
    background-color: #1c1c1c;
}

.modal-header {
    border-bottom: 1px solid #2e2e2e;
}

.modal-footer {
    border-top: 1px solid #2e2e2e;
}

.close,
.close:hover {
    color: #fff;
}

.scroll-to-top {
    background: rgba(52, 58, 64, 0.5) none repeat scroll 0 0;
    border-radius: 70px !important;
    bottom: 86px;
    color: #fff;
    display: none;
    height: 50px;
    line-height: 46px;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
    color: white;
}

.scroll-to-top:hover {
    background: #343a40;
}

.scroll-to-top i {
    font-weight: 800;
}

.smaller {
    font-size: 0.7rem;
}

.o-hidden {
    overflow: hidden !important;
}

.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

.navbar-nav .form-inline .input-group {
    width: 100%;
}

.bg-white {
    background-color: #232323 !important;
}

.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
}

.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
    content: '\f107';
}

.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
    display: none;
}

.navbar-nav .nav-item .nav-link:focus {
    outline: none;
}

.navbar-nav .nav-item .nav-link .badge {
    border: medium none !important;
    border-radius: 3px;
    font-size: 9px;
    font-weight: 700;
    height: 15px;
    line-height: 9px;
    min-width: 15px;
    position: absolute;
    text-align: center;
    top: 10px;
    right: 0;
}

@media (min-width: 768px) {
    .navbar-nav .form-inline .input-group {
        width: auto;
    }
}

.sidebar {
    width: 90px !important;
    background: #1c1c1c;
    min-height: 100%;
    z-index: 9;
    padding: 2px 0;
    position: fixed;
    height: 100%;
}

.sidebar:hover {
    overflow: auto;
}

.sidebar .nav-item:last-child {
    margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
    font-size: 14px;
    padding: 12px 14px !important;
    text-align: center;
    width: 90px;
    font-weight: 600;
}

.sidebar .nav-item .nav-link:hover,
.sidebar .nav-item.active .nav-link {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar .nav-item .nav-link .fas {
    color: #ffffff;
}

.sidebar .nav-item .nav-link span {
    display: inline;
    font-size: 14px;
    margin: 0 0 0 11px;
}

.sidebar .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.0rem) !important;
    margin: 0;
}

.sidebar .nav-item .dropdown-menu.dropup {
    bottom: 0;
    top: auto !important;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: none;
}

.sidebar .nav-item .nav-link {
    color: #ffffff;
}

.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
}

/* .sidebar.toggled {
    width: 0 !important;
    overflow: hidden;
} */

@media (min-width: 768px) {
    .sidebar {
        height: 100%;
        position: fixed;
        width: 225px !important;
    }

    .sidebar:hover {
        overflow: auto;
    }

    .sidebar .nav-item .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 225px;
    }

    .sidebar .nav-item .nav-link span {
        display: inline;
        font-size: 13px;
        margin: 0 0 0 11px;
    }

    .sidebar .nav-item .dropdown-menu {
        position: static !important;
        margin: 0 1rem;
        top: 0;
    }

    .sidebar .nav-item.dropdown .dropdown-toggle::after {
        display: block;
    }

    .sidebar.toggled {
        overflow: visible;
        width: 90px !important;
    }

    .sidebar.toggled:hover {
        overflow-x: auto;
    }

    .sidebar.toggled .nav-item:last-child {
        margin-bottom: 1rem;
    }

    .sidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem 1rem;
        width: 90px;
    }

    .sidebar.toggled .nav-item .nav-link span {
        margin: 0;
    }

    .sidebar.toggled .nav-item .nav-link span {
        font-size: 0.65rem;
        display: block;
    }

    .sidebar.toggled .nav-item .dropdown-menu {
        position: absolute !important;
        -webkit-transform: none !important;
        transform: none !important;
        left: calc(90px + 0.5rem) !important;
        margin: 0;
    }

    .sidebar.toggled .nav-item .dropdown-menu.dropup {
        bottom: 0;
        top: auto !important;
    }

    .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
        display: none;
    }
}

.sidebar.fixed-top {
    top: 56px;
    height: calc(100vh - 56px);
    overflow-y: auto;
}

.card-body-icon {
    position: absolute;
    z-index: 0;
    top: -1.25rem;
    right: -1rem;
    opacity: 0.4;
    font-size: 5rem;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 1;
        column-count: 1;
    }
}

@media (min-width: 768px) {
    .card-columns {
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        -webkit-column-count: 2;
        column-count: 2;
    }
}

:root {
    --input-padding-x: 0.75rem;
    --input-padding-y: 0.75rem;
}

.card-login {
    max-width: 25rem;
}

.card-register {
    max-width: 40rem;
}

.form-label-group {
    position: relative;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
    height: auto;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

footer.sticky-footer {
    background-color: #232323;
    padding: 15px;
    margin-left: 225px;
}

footer.sticky-footer .copyright {
    line-height: 1;
    font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
    width: 100%;
}

@media (min-width: 768px) {
    body.sidebar-toggled footer.sticky-footer {
        width: calc(100% - 90px);
        margin-left: 90px;
    }
}

/* Nav */


.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: #fff;
}

.osahan-navbar-search .form-control {
    background: #121212 none repeat scroll 0 0;
    border: 1px solid #303030;
    font-size: 14px;
    max-width: 607px !important;
    width: 607px !important;
}

.osahan-navbar-search {
    margin-right: 84px !important;
}

.osahan-navbar-search .btn {
    background: #303030 none repeat scroll 0 0;
    border: medium none;
    font-size: 14px;
    padding: 0 18px;
}

.osahan-right-navbar .nav-link {
    padding: 17px 13px !important;
    position: relative;
}

.osahan-right-navbar {
    margin: 0 7px 0 0;
}

.user-dropdown-link img {
    border-radius: 50px;
    height: 30px;
    margin: -9px 2px -6px 0;
    width: 30px;
}

.top-mobile-search {
    display: none;
}

.top-mobile-search {
    margin: -16px -15px 19px -18px;
}

.top-mobile-search input {
    border-radius: 0;
    box-shadow: none !important;
}

.top-mobile-search .btn {
    border-radius: 0;
}

/* Sidebar */
.sidebar::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}

.channel-sidebar-list {
    border-top: 1px solid #2e2e2e;
    margin: 6px 0 0;
    padding: 7px 14px;
}

.channel-sidebar-list h6 {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 9px;
}

.channel-sidebar-list ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.channel-sidebar-list ul li img {
    border-radius: 50px;
    height: 28px;
    margin: 0 14px 0 0;
    width: 28px;
}

.channel-sidebar-list li a {
    color: #fff;
    display: inline-block;
    overflow: hidden;
    padding: 6px 0;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.channel-sidebar-list li a .badge {
    float: right;
    margin: 7px 0 0;
    position: absolute;
    right: 20px;
}

.sidebar.toggled .nav-item .nav-link span {
    margin: 0;
}

.sidebar.toggled .channel-sidebar-list h6 {
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sidebar.toggled .channel-sidebar-list {
    padding: 7px;
    text-align: center;
}

.sidebar.toggled .channel-sidebar-list li img {
    display: list-item;
    margin: auto auto 4px;
}

.sidebar.toggled .channel-sidebar-list li a {
    font-size: 10px;
    font-weight: 500;
}

.sidebar.toggled .channel-sidebar-list li .badge {
    display: none;
}

/* Customize Bootstrap */
.border-none {
    border: none;
}

.bg-primary,
.btn-primary,
.btn-outline-primary:hover,
.badge-primary {
    border-color: transparent !important;
    background: #ff0000;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff0000 0%, #e41e1d 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#e41e1d', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-primary {
    border-color: #ff0000;
    color: #ff0000;
}

.text-primary {
    color: #ff0000 !important;
}

.progress-bar {
    background-color: #ff0000;
}

.bg-success,
.btn-success,
.btn-outline-success:hover,
.badge-success {
    border-color: transparent !important;
    background: #4eda92;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #4eda92 1%, #56e0cb 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #4eda92 1%, #56e0cb 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4eda92 1%, #283331 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4eda92', endColorstr='#56e0cb', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-success {
    border-color: #4eda92;
    color: #4eda92;
}

.text-success {
    color: #4eda92 !important;
}

.bg-warning,
.btn-warning,
.btn-outline-warning:hover,
.badge-warning {
    border-color: transparent !important;
    background: #ffc107;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ffc107 1%, #fffc07 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc107', endColorstr='#fffc07', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-warning {
    border-color: #ffc107;
    color: #ffc107;
}

.text-warning {
    color: #ffc107 !important;
}

.bg-danger,
.btn-danger,
.btn-outline-danger:hover,
.badge-danger {
    border-color: transparent !important;
    background: #ff253a;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff253a', endColorstr='#ff8453', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-danger {
    border-color: #ff253a;
    color: #ff253a;
}

.text-danger {
    color: #ff253a !important;
}

.bg-secondary,
.btn-secondary,
.btn-outline-secondary:hover,
.badge-secondary {
    border-color: transparent !important;
    background: #9ba8aa;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #9ba8aa 0%, #92b4d2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ba8aa', endColorstr='#92b4d2', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-secondary {
    border-color: #9ba8aa;
    color: #9ba8aa;
}

.text-secondary {
    color: #9ba8aa !important;
}

.bg-info,
.btn-info,
.btn-outline-info:hover,
.badge-info {
    border-color: transparent !important;
    background: #17a2b8;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #17a2b8 1%, #30def9 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#17a2b8', endColorstr='#30def9', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-info {
    border-color: #17a2b8;
    color: #17a2b8;
}

.text-info {
    color: #17a2b8 !important;
}

.bg-dark,
.btn-dark,
.btn-outline-dark:hover,
.badge-dark {
    border-color: transparent !important;
    background: #171b20;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #171b20 1%, #343a40 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#171b20', endColorstr='#343a40', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-dark {
    border-color: #171b20;
    color: #171b20;
}

.text-dark {
    color: #ffffff !important;
}

.btn-light {
    border: medium none;
}

.adblock {
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 2px;
    margin-bottom: 1rem;
    padding: 27px 10px;
    text-align: center;
    width: 100%;
}

.main-title>h6 {
    margin: 0px 0 16px;
}

.top-category .main-title>h6 {
    margin-bottom: 10px;
}

.right-action-link .fa {
    background: #333333 none repeat scroll 0 0;
    border-radius: 50px;
    display: inline-block;
    height: 20px;
    line-height: 19px;
    margin: 0 0 0 3px;
    text-align: center;
    width: 20px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #eceff0 !important;
    color: #888888 !important;
    text-decoration: none;
}

.form-control,
.form-control:focus {
    background: #1c1c1c none repeat scroll 0 0;
    border-color: #303030;
    border-radius: 2px;
    font-size: 13px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #121212;
}

.custom-select {
    background-color: #1c1c1c;
    border-color: #303030;
    border-radius: 2px;
    font-size: 13px;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.category-checkbox .custom-control {
    margin: 0 0 6px !important;
}

label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 5px;
}

a {
    color: #ff0000;
    text-decoration: none ;
}

.btn {
    border-radius: 2px;
}

.btn-light {
    background: #f8f8f8 none repeat scroll 0 0;
    border-color: #d3d3d3;
    color: #828282;
}

.dropdown-header {
    color: #fff;
    padding: 7px 13px;
}

.dropdown-menu {
    background: #232323 none repeat scroll 0 0;
    border-color: #232323;
    border-radius: 2px;
    margin: 0;
}

.dropdown-menu .dropdown-item {
    color: #d8d8d8;
    font-size: 12px;
    padding: 6px 14px !important;
}

.dropdown-item {
    color: #d8d8d8;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #4c4c4c;
}

.dropdown-divider {
    border-top: 1px solid #353535;
}

a:hover,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff !important;
}

.upload-video .fas {
    font-size: 200px;
}

.badge,
.alert {
    border-radius: 2px;
}

.pagination .page-item .page-link {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    line-height: 20px;
}

.pagination .page-item.active .page-link {
    background: #ff0000 none repeat scroll 0 0;
    border-radius: 2px;
    color: #fff;
    height: 30px;
    margin: 0 7px;
    text-align: center;
    width: 30px;
}

.pagination .page-item.disabled .page-link {
    opacity: 0.5;
}

.modal-content {
    border: medium none;
    border-radius: 2px;
}

/* Category Slider */
.category-item img {
    background: #1c1c1c none repeat scroll 0 0;
    border: 2px solid #1c1c1c;
    border-radius: 50px;
    height: 60px;
    margin: 1px 0 8px;
    object-fit: scale-down;
    transform: scale(1);
    width: 60px;
}

.category-item:hover img {
    transform: scale(1.09);
    transition-duration: 0.4s;
}

.category-item {
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 2px;
    margin: 6px 2px;
    padding: 24px 12px;
    text-align: center;
}

.top-category .category-item {
    padding: 20px 11px;
}

.category-item h6 {
    font-size: 13px;
    margin: 5px 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.category-item p {
    font-size: 11px;
    font-weight: 500;
    margin: 0;
}

/* maoury */

.grid {
    column-count: 10;
    column-gap: .5em;
    margin: auto;
    width: 100%;
    padding : .5em;
    
  }
  
  img {
    height: auto;
    max-width: 100%;
    
  }
  @media (max-width: 1199px) {
    .grid {
      column-count: 4;
  }
}
  @media(max-width: 1024px) {
    .grid {
      column-count: 3;
  }
}
  
  @media(max-width: 800px) {
      .grid {
        column-count: 3;
    }
  }
  
  @media(max-width: 600px) {
      .grid {
        column-count: 2;
    }
  }
  
  @media(max-width: 400px) {
      .grid {
        column-count: 1;
    }
  }

/* Video Card */
.video-block .mb-3 {
    margin-bottom: 30px !important;
}

.video-card-image {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}

.time {
    background: #f5022f none repeat scroll 0 0;
    border-radius: 2px;
    bottom: 2%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 6px;
    position: absolute;
    right: 5px;
}

.releace {
    background: #f70834 none repeat scroll 0 0;
    border-radius: 2px;
    bottom: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 6px;
    position: absolute;
    left: 5px;
}

.laguage {

    background: #f70834 none repeat scroll 0 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 6px;
    position: absolute;
    top: 5px;
    left: 5px;

}

.adult {
    background: #f70834 none repeat scroll 0 0;
    border-radius: 2px;

    color: #fff;
    font-size: 14px;
    font-weight: 500;

    padding: 0 6px;
    position: absolute;
    top: 5px;
    right: 5px;


}

.video-card-image img {
    width: 100%;
}

.play-icon {
    opacity: 0;
    position: absolute;
}

.play-icon .fas {
    bottom: 0;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
}

.video-card:hover .play-icon {
    background: #000 none repeat scroll 0 0;
    border-radius: 2px;
    bottom: 0;
    color: #fff;
    font-size: 35px;
    left: 0;
    opacity: 0.4;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition-duration: 0.4s;
}

.video-title a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}

.video-title {
    margin-bottom: 12px;
}

.video-page {
    font-size: 11px;
}

.video-view {
    color: #acacac;
    font-size: 10px;
    font-weight: 500;
    margin: 2px 0 0;
}

.video-card {
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 2px;
    transition-duration: 0.4s;
    margin-bottom: .5em;
}

.video-card-body {
    padding: 12px;
}

.video-card-list .video-card-image {
    float: left;
    height: 77px;
    margin: 0 12px 0 0;
    width: 122px;
}

.video-card-list .video-card-body {
    padding: 0;
}

.video-card-list {
    background: transparent none repeat scroll 0 0;
    box-shadow: none;
    display: inline-block;
    margin-bottom: 1rem;
    width: 100%;
}

.video-card-list .video-card-body .video-title a {
    font-size: 13px;
}

.video-card-list .video-card-body .video-title {
    line-height: 17px;
}

.video-card-list .video-card-body .right-action {
    opacity: 0;
}

.video-card-list:hover .video-card-body .right-action {
    opacity: unset;
}

/* Channels Card */
.channels-card {
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 2px;
    padding: 24px 12px;
    position: relative;
    text-align: center;
    transition-duration: 0.4s;
}

.channels-card::after {
    background: #333333 none repeat scroll 0 0;
    border-radius: 0 0 5px 6px;
    bottom: -4px;
    content: "";
    height: 7px;
    left: 15px;
    position: absolute;
    right: 15px;
    z-index: -1;
}

.channels-card:hover::after {
    background: #484848 none repeat scroll 0 0;
}

.channels-title a {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
}

.channels-view {
    color: #acacac;
}

.channels-card-image .btn {
    font-size: 12px;
    padding: 3px 10px;
}

.channels-card-image .btn strong {
    margin: 0 0 0 5px;
}

.channels-card-image img {
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 50px;
    height: 80px;
    margin: 1px 0 8px;
    object-fit: scale-down;
    transform: scale(1);
    width: 80px;
}

.channels-card-image-btn {
    margin: 3px 0 7px;
}

/* Login Pages */
.login-main-wrapper {
    background: #ff0000;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff0000 0%, #e41e1d 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#e41e1d', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    height: calc(100vh - 0rem);
}

.full-height {
    height: calc(100vh - 0rem);
}

.login-main-left {
    margin: auto;
    max-width: 335px;
}

.login-main-right.bg-white {
    border-radius: 2px;
    margin: auto;
    max-width: 561px;
}

.carousel-login-card h5 {
    font-size: 18px;
}

.login-main-wrapper .form-control {
    background: transparent none repeat scroll 0 0;
}

.login-main-right .owl-theme .owl-controls .owl-page span {
    background: #dcdcdc;
    border-radius: 50px;
    height: 16px;
    margin: 0 2px;
    opacity: unset;
    width: 16px;
}

.btn-lg {
    font-size: 15px;
    padding: 12px 16px;
}

.login-main-right .owl-theme .owl-controls .owl-page.active span,
.login-main-right .owl-theme .owl-controls .owl-page span:hover {
    background: #ff0000;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff0000 0%, #e41e1d 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff0000 0%, #e41e1d 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#e41e1d', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

/* Upload Details */
.imgplace {
    background-color: #8781bd;
    border-radius: 3px;
    height: 94px;
    width: 100%;
}

.osahan-title {
    color: #fff;
    font-weight: 600;
}

.osahan-size {
    font-size: 11px;
}

.osahan-progress .progress {
    border-radius: 2px;
    height: 8px;
    margin: 14px 0;
}

.osahan-close {
    position: absolute;
    right: 18px;
    top: 0;
}

.osahan-desc {
    font-size: 12px;
    opacity: 0.7;
}

/* Watch History */
.video-close {
    color: #fff !important;
    font-size: 18px;
    position: absolute;
    right: 6px;
    z-index: 9;
}

.progress {
    background-color: #333333;
}

.history-video .progress {
    border-radius: 2px;
    font-size: 9px;
    font-weight: 700;
    height: 13px;
    margin: 12px 14px -4px 13px;
}

/* Single Channel */
.single-channel-image {
    position: relative;
}

.single-channel-nav .navbar {
    padding: 0;
}

.single-channel-nav {
    background: #232323 none repeat scroll 0 0 !important;
    padding: 0rem 30px 0;
}

.channel-brand {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.single-channel-nav .nav-link {
    border-bottom: 2px solid transparent;
    margin: 0 12px;
    padding: 14px 0 !important;
}

.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover {
    border-color: #ff0000;
    color: #ff0000;
}

.single-channel-nav .navbar-nav {
    margin: 0 0 0 40px;
}

.channel-profile {
    bottom: 0;
    left: 0;
    padding: 1rem 30px;
    position: absolute;
    right: 0;
}

.single-channel-image .img-fluid {
    width: 100%;
}

.channel-profile-img {
    background: #fff none repeat scroll 0 0;
    border: 6px solid #fff;
    border-radius: 50px;
    height: 90px;
    width: 90px;
}

.social {
    color: #fff;
    float: right;
    margin: 62px 0 0;
}

.social a {
    background: #000 none repeat scroll 0 0;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
}

/* Video Page */
.box {
    padding: 15px;
    background: #1c1c1c none repeat scroll 0 0;
    border-radius: 2px;
    transition-duration: 0.4s;
}

.single-video-title h2 {
    font-size: 18px;
}

.single-video-title h2 a {
    color: #fff;
}

.single-video-author img {
    border-radius: 50px;
    float: left;
    height: 38px;
    margin: 0 13px 0 0;
    width: 38px;
}

.single-video-author p {
    margin: 0;
    padding: 0;
}

.single-video-info-content h6 {
    font-size: 15px;
    font-weight: 600;
}

.tags span a {
    background: #333333 none repeat scroll 0 0;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 4px 9px;
}



/* Footer */
.app img {
    background: #231f20 none repeat scroll 0 0;
    border-radius: 3px;
    height: 38px;
    margin: 5px 0 0;
    object-fit: scale-down;
    padding: 3px 4px;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .top-mobile-search {
        display: block;
    }

    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 108px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar .nav-item .nav-link {
        padding: 8px 14px !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 15px;
    }

    .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .sidebar-toggled .single-channel-page {
        padding: 0 !important;
    }

    .single-channel-page .container-fluid {
        padding: 30px 15px 15px !important;
    }

    .single-video-author .float-right {
        float: none !important;
        margin: 0 0 14px;
    }

    .single-video-author {
        text-align: center;
    }

    .single-video-author img {
        float: none;
        margin: 0 0 5px;
    }

    .tags span a {
        margin: 0 0 3px;
    }

    .sidebar .nav-item .nav-link span {
        margin: 0 !important;
    }

    .sidebar .channel-sidebar-list h6 {
        font-size: 11px !important;
    }

    .sidebar .nav-item .nav-link span {
        display: block;
        font-size: 0.65rem;
    }

    .video-card-image img {
        width: 100%;
    }

    footer {
        padding: 15px 0 !important;
        text-align: center;
    }

    footer .text-right {
        text-align: center !important;
    }

    .osahan-right-navbar .nav-link {
        font-size: 0;
        padding: 22px 5px !important;
    }

    .osahan-right-navbar .nav-link .fas {
        font-size: 12px;
    }

    #sidebarToggle {
        margin: 0 9px 0 0;
    }

    .navbar-brand img {
        vertical-align: top;
    }

    .owl-theme .owl-controls .owl-buttons div {
        margin: 4px !important;
    }

    .single-channel-nav {
        padding: 15px;
    }

    .channel-profile {
        padding: 10px;
        position: relative;
        text-align: center;
    }

    .social {
        display: none;
    }

    .navbar-toggler {
        padding: 1px 3px;
    }

    .single-channel-nav .navbar-nav {
        margin: 0;
    }

    .single-channel-nav .nav-link {
        margin: 0;
    }

    .imgplace {
        margin-bottom: 15px;
    }

    .osahan-title {
        padding: 0 25px 5px 0;
    }

    footer.sticky-footer {
        margin-left: 90px;
    }

    .sidebar-toggled footer.sticky-footer {
        margin: 0;
    }

    .sidebar .channel-sidebar-list h6 {
        font-size: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .sidebar .channel-sidebar-list {
        padding: 7px;
        text-align: center;
    }

    .sidebar .channel-sidebar-list li img {
        display: list-item;
        margin: auto auto 4px;
    }

    .sidebar .channel-sidebar-list li a {
        font-size: 10px;
        font-weight: 500;
    }

    .sidebar .channel-sidebar-list li .badge {
        display: none;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .top-mobile-search {
        display: block;
    }

    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 108px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar .nav-item .nav-link {
        padding: 8px 14px !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 15px;
    }

    .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .sidebar-toggled .single-channel-page {
        padding: 0 !important;
    }

    .single-channel-page .container-fluid {
        padding: 30px 15px 15px !important;
    }

    .container {
        max-width: 100%;
    }

    .tags span a {
        margin: 0 0 3px;
    }

    .sidebar .nav-item .nav-link span {
        margin: 0 !important;
    }

    .sidebar .channel-sidebar-list h6 {
        font-size: 11px !important;
    }

    .sidebar .nav-item .nav-link span {
        display: block;
        font-size: 0.65rem;
    }

    .video-card-image img {
        width: 100%;
    }

    footer {
        padding: 15px 0 !important;
        text-align: left;
    }

    footer .text-right {
        text-align: right !important;
    }

    .osahan-right-navbar .nav-link {
        font-size: 0;
        padding: 22px 5px !important;
    }

    .osahan-right-navbar .nav-link .fas {
        font-size: 12px;
    }

    #sidebarToggle {
        margin: 0 9px 0 0;
    }

    .navbar-brand img {
        vertical-align: top;
    }

    .owl-theme .owl-controls .owl-buttons div {
        margin: 4px !important;
    }

    .single-channel-nav {
        padding: 15px;
    }

    .channel-profile {
        padding: 10px;
        position: relative;
        text-align: center;
    }

    .social {
        display: none;
    }

    .navbar-toggler {
        padding: 1px 3px;
    }

    .single-channel-nav .navbar-nav {
        margin: 0;
    }

    .single-channel-nav .nav-link {
        margin: 0;
    }

    .imgplace {
        margin-bottom: 15px;
    }

    .osahan-title {
        padding: 0 25px 5px 0;
    }

    footer.sticky-footer {
        margin-left: 90px;
    }

    .sidebar-toggled footer.sticky-footer {
        margin: 0;
    }

    .sidebar .channel-sidebar-list h6 {
        font-size: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .sidebar .channel-sidebar-list {
        padding: 7px;
        text-align: center;
    }

    .sidebar .channel-sidebar-list li img {
        display: list-item;
        margin: auto auto 4px;
    }

    .sidebar .channel-sidebar-list li a {
        font-size: 10px;
        font-weight: 500;
    }

    .sidebar .channel-sidebar-list li .badge {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 243px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar .nav-item .nav-link {
        padding: 8px 14px !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 105px;
    }

    .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .sidebar-toggled .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .single-channel-page .container-fluid {
        padding: 30px 15px 15px !important;
    }

    .video-card-image img {
        width: 100%;
    }

    .osahan-right-navbar .nav-link {
        font-size: 0;
        padding: 22px 5px !important;
    }

    .osahan-right-navbar .nav-link .fas {
        font-size: 12px;
    }

    #sidebarToggle {
        margin: 0 9px 0 0;
    }

    .navbar-brand img {
        vertical-align: top;
    }

    .owl-theme .owl-controls .owl-buttons div {
        margin: 4px !important;
    }

    .container {
        max-width: 100%;
    }

    .single-channel-nav {
        padding: 15px;
    }

    .channel-profile {
        padding: 15px;
    }

    .social {
        display: none;
    }

    .navbar-toggler {
        padding: 1px 3px;
    }

    .single-channel-nav .navbar-nav {
        margin: 0;
    }

    .single-channel-nav .nav-link {
        margin: 0;
    }

    .osahan-navbar-search .form-control {
        width: auto !important;
    }

    .osahan-navbar-search {
        margin-right: 15px !important;
    }

    .imgplace {
        margin-bottom: 15px;
    }

    .osahan-title {
        padding: 0 25px 5px 0;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        /* padding-left: 243px; */
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar .nav-item .nav-link {
        padding: 8px 14px !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 105px;
    }

    .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .sidebar-toggled .single-channel-page {
        padding: 0 0 0 90px !important;
    }

    .single-channel-page .container-fluid {
        padding: 30px 15px 15px !important;
    }

    .video-card-image img {
        width: 100%;
    }

    .osahan-right-navbar .nav-link {
        font-size: 0;
        padding: 22px 5px !important;
    }

    .osahan-right-navbar .nav-link .fas {
        font-size: 12px;
    }

    #sidebarToggle {
        margin: 0 9px 0 0;
    }

    .navbar-brand img {
        vertical-align: top;
    }

    .owl-theme .owl-controls .owl-buttons div {
        margin: 4px !important;
    }

    .container {
        max-width: 100%;
    }

    .single-channel-nav {
        padding: 0 15px;
    }

    .channel-profile {
        padding: 15px;
    }

    .social {
        display: none;
    }

    .navbar-toggler {
        padding: 1px 3px;
    }

    .single-channel-nav .navbar-nav {
        margin: 0 0 0 20px;
    }

    .single-channel-nav .nav-link {
        margin: 0 7px;
    }

    .single-channel-nav .form-control,
    .single-channel-nav .btn-outline-success {
        display: none;
    }

    .osahan-navbar-search .form-control {
        width: auto !important;
    }

    .osahan-navbar-search {
        margin-right: 15px !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}